<template>
    <div class="star_wrap">
        <lottie-comp :options="options" :width="220" :height="220" v-on:animCreated="handleAnimation" />
    </div>
</template>
<script>
import Lottie from 'vue-lottie';
import checkaniamtion from '@/assets/json/lottie/star.json';
export default {
  name: 'StarComp',
  components: {
    'lottie-comp': Lottie
  },
  data () {
    return {
      anim: null,
      options: { animationData: checkaniamtion }
    };
  },

  mounted () {},

  methods: {
    handleAnimation (anim) {
      anim.loop = false;
      anim.addEventListener('complete', () => {
        this.$store.commit('player/setIsOpenAnim', false);
      });
    }
  }
};
</script>
<style scoped>
.star_wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 220px;
  height: 220px;
  transform: translate(-50%, -50%);
  z-index: 99;
}
</style>
